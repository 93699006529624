import useSWR from "swr";

import { FILTERED_STORE_IDS } from "@/shared/constants";

import { axios } from "@/utils/axios";
import logger from "@/utils/logger";

import { AutocompleteResponse } from "@/types/search";

export async function fetcher<T>(url: string, httpCorrelationId: string): Promise<T> {
    const res = await axios({
        url,
        method: "GET",
        headers: { "cor-id": httpCorrelationId, "REQUEST-SOURCE": "Web" },
    });

    return res.data;
}

export function useAutocomplete(
    searchTerm: string,
    httpCorrelationId: string,
    locale: string,
    searchVersion: string
): AutocompleteResponse {
    const url = `/api/stores/autocomplete?${`q=${encodeURIComponent(
        searchTerm
    )}&locale=${locale}&search_version=${searchVersion}`}`;

    const { data: autocompleteData, error: autocompleteError } = useSWR<AutocompleteResponse>(
        searchTerm ? [url, httpCorrelationId] : null,
        fetcher
    );

    logger.logException(autocompleteError, `Failed to search for store ${searchTerm}`);

    // Temporarily filter out Walmart and Sam's Club Gift Card Stores via store id (in useSearchStores as well)
    // This is a hack. Please fix afterwards
    // @ts-ignore
    if (autocompleteData) {
        autocompleteData.stores = autocompleteData.stores.filter(
            (store) => !FILTERED_STORE_IDS.includes(store.id)
        );
    }

    return autocompleteData;
}
