import { BuilderContent as BuilderContentVariation } from "@builder.io/sdk";
import classNames from "classnames/bind";
import Link from "next/link";
import React, { useState } from "react";

import useViewport from "@/hooks/useViewport";

import { UI_CONTEXTS } from "../../analytics/constants";
import { useCurrentLocale } from "../../context/LanguageContext";
import { ChunkedNav, GenericNavItem } from "../../interfaces/navigation-interface";
import { KINDS, SIZES } from "../buttons/buttons.constants";
import LinkButton from "../buttons/link-button";
import { ApLogo } from "../icons/ap-logo";
import { MobileNavigation } from "../mobile-navigation/mobile-navigation";
import SearchBar from "../search-bar/search-bar";
import { Auth } from "./auth/auth";
import styles from "./navigation.module.scss";
import { TopLevelNavigation } from "./top-level-navigation/top-level-navigation";

const cx = classNames.bind(styles);

export interface Props {
    builderNavLinks?: Array<BuilderContentVariation>;
    navigationItems: ChunkedNav;
    mobileFooterNavigationItems?: Array<Array<GenericNavItem>>;
}

export const Navigation: React.FC<Props> = (props: Props) => {
    const { builderNavLinks, navigationItems, mobileFooterNavigationItems } = props;
    const locale = useCurrentLocale();
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
    const { isMobile } = useViewport();

    const mobileMenuIconClassName = cx({
        [styles.mobileMenuIcon]: true,
        [styles.mobileMenuIconActive]: isMobileMenuActive,
    });

    // The auth element needs to be sorted differently on mobile in order to
    // render correctly. On desktop, the search box is "before" the auth
    // element. On mobile, it comes after. Here, we are just putting those two
    // elements in the order they need to be in on desktop.
    //
    // In the case of mobile, we swap them.
    const navElementOrder: [React.ReactNode, React.ReactNode] = [
        <div className={styles.searchInputContainer}>
            <SearchBar />
        </div>,
        <Auth />,
    ];
    if (isMobile) {
        [navElementOrder[0], navElementOrder[1]] = [navElementOrder[1], navElementOrder[0]];
    }

    return (
        <div data-testid="global-header" className={styles.navWrapper}>
            <div className={styles.navBorder}>
                <div className={styles.navContainer}>
                    <div className={styles.mobileNavigationContainer}>
                        <div
                            onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
                            className={mobileMenuIconClassName}
                        />
                    </div>
                    <div className={styles.logoContainer}>
                        <Link prefetch={false} href="/">
                            <a className={styles.logo}>
                                <ApLogo />
                            </a>
                        </Link>
                        <div className={styles.navigationLinksContainer}>
                            {builderNavLinks &&
                                builderNavLinks.map(({ data }, index) => {
                                    if (data?.link?.url && data?.link?.text) {
                                        return (
                                            <LinkButton
                                                kind={KINDS.UNSET}
                                                size={SIZES.SMALL}
                                                label={data.link.text}
                                                url={data.link.url}
                                                newTab={data.link.newTab}
                                                overrideFocusBorder={false}
                                                uiContext={UI_CONTEXTS.TOP_NAVIGATION}
                                                passHref={data.link.passHref}
                                                forwardUtm={data.link.forwardUtm}
                                                key={index}
                                            />
                                        );
                                    }
                                })}
                        </div>
                    </div>
                    {navElementOrder[0]}
                    {navElementOrder[1]}
                </div>
                <TopLevelNavigation navigationItems={navigationItems} />

                <MobileNavigation
                    navigationItems={navigationItems}
                    isMobileMenuActive={isMobileMenuActive}
                    footerNavigationItems={mobileFooterNavigationItems}
                    locale={locale}
                />
            </div>
        </div>
    );
};
