import React, { KeyboardEvent, useState } from "react";

import { ChunkedNav } from "../../../interfaces/navigation-interface";
import styles from "../navigation.module.scss";
import { SubNavigation } from "../sub-navigation/sub-navigation";
import { TopLevelNavigationItem } from "./top-level-navigation-item/top-level-navigation-item";

export interface Props {
    navigationItems: ChunkedNav;
}

const SubNavigationItems = ({ navigationItems, activeId }) => {
    if (!activeId) {
        return null;
    }

    return navigationItems
        .filter(([navItem]) => activeId === navItem.id)
        .map(([navItem, subNavItems]) => {
            return <SubNavigation key={navItem.id} subMenuItemChunks={subNavItems} />;
        });
};

export const TopLevelNavigation: React.FC<Props> = (props: Props) => {
    const { navigationItems } = props;

    const [activeId, setActiveId] = useState(undefined);

    const handleKeyDown = (e: KeyboardEvent, id: string) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setActiveId(id);
        }
    };
    return (
        <div
            className={styles.topLevelNavigationContainer}
            onMouseLeave={() => setActiveId(undefined)}
        >
            <ul className={styles.topLevelNavigationList}>
                {navigationItems.map(([{ id, text, link, categoryId, bold }]) => (
                    <TopLevelNavigationItem
                        key={id}
                        active={activeId === id}
                        categoryId={categoryId}
                        text={text}
                        link={link}
                        bold={bold}
                        onMouseEnter={() => setActiveId(id)}
                        onKeyDown={(e) => handleKeyDown(e, id)}
                    />
                ))}
            </ul>

            <SubNavigationItems navigationItems={navigationItems} activeId={activeId} />
        </div>
    );
};
