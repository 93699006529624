import { useTranslation } from "next-i18next";
import Link from "next/link";
import React from "react";

import { GenericNavItem } from "../../../interfaces/navigation-interface";
import { Locale } from "../../../types/locale";
import { ApLogoMini } from "../../icons/ap-logo-mini";
import styles from "../mobileNavigation.module.scss";

interface Props {
    navigationItems: Array<Array<GenericNavItem>>;
    locale?: Locale;
}

export const MobileNavigationFooter: React.FC<Props> = (props: Props) => {
    const { navigationItems = [] } = props;
    const { t } = useTranslation();

    return (
        <div className={styles.mobileNavigationFooterContainer}>
            <div className={styles.footerNavigationItemsContainer}>
                {navigationItems.flat().map((item) => {
                    return (
                        <div key={item.id} className={styles.footerNavigationItem}>
                            {item.link ? (
                                <Link prefetch={false} href={item.link}>
                                    <a>
                                        <h2>{item.text}</h2>
                                    </a>
                                </Link>
                            ) : (
                                item.text
                            )}
                        </div>
                    );
                })}
            </div>
            <div className={styles.footerInformationContainer}>
                <div className={styles.footerLogo}>
                    <ApLogoMini width="40" height="36" />
                </div>
                <div className={styles.footerServiceContent}>
                    <p dangerouslySetInnerHTML={{ __html: t("footer.legalText.line1") }}></p>
                    <br />
                    <p dangerouslySetInnerHTML={{ __html: t("footer.legalText.line2") }}></p>
                </div>
            </div>
        </div>
    );
};
