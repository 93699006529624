import className from "classnames/bind";
import React, { useEffect, useRef } from "react";

import { UI_CONTEXTS } from "../../../../analytics/constants";
import EVENTS from "../../../../analytics/events";
import { GenericNavItem } from "../../../../interfaces/navigation-interface";
import { NAVIGATION_ITEM_COLORS } from "../../../../shared/constants";
import { KINDS, SIZES } from "../../../buttons/buttons.constants";
import LinkButton from "../../../buttons/link-button";
import styles from "../../navigation.module.scss";

const cx = className.bind(styles);

export interface Props {
    navigationItem: GenericNavItem;
    isFirst: boolean;
}

export const SubNavigationItem: React.FC<Props> = ({ navigationItem, isFirst }) => {
    const { bold, color, link, categoryId, text } = navigationItem;
    const firstItemRef = useRef(null);

    useEffect(() => {
        if (firstItemRef.current) {
            firstItemRef.current.firstChild.focus();
        }
    }, [firstItemRef]);

    const subNavigationItemClassName = cx({
        [styles.subNavigationItem]: true,
        [styles.subNavigationItemHeader]: !link,
        [styles.subNavigationItemHover]: link,
        [styles.subNavigationItemBold]: bold,
        [styles.subNavigationItemBlack]: color?.name === NAVIGATION_ITEM_COLORS.BLACK,
        [styles.subNavigationItemFire]: color?.name === NAVIGATION_ITEM_COLORS.FIRE,
        [styles.subNavigationItemOrca]: color?.name === NAVIGATION_ITEM_COLORS.ORCA,
    });

    if (!link) {
        return <li className={subNavigationItemClassName}>{text}</li>;
    }

    return (
        <li className={subNavigationItemClassName} ref={isFirst ? firstItemRef : null}>
            <LinkButton
                disabled={false}
                fluid={false}
                newTab={false}
                kind={KINDS.UNSET}
                size={SIZES.UNSET}
                url={link}
                label={text}
                overrideFocusBorder={false}
                analytics={{
                    uiContext: UI_CONTEXTS.TOP_NAVIGATION,
                    eventName: EVENTS.SHOP_DIRECTORY_CATEGORY_LIST_CLICK,
                    eventProps: {
                        categoryId,
                        categoryName: text,
                    },
                }}
            />
        </li>
    );
};
